.amazonSmile {
  background-image: url('/amazon-smile-background.png');
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #ffffff;
  padding: 100px 0;

  .amazonSmileContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .amazonSmileLeft {
    width: 60%;
  }

  .amazonSmileRight {

  }

  .amazonSmileLogo {
    display: none;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }

  .amazonSmileTitle {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .amazonSmileDescription {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 22px;
  }

  .amazonSmileInfo {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .amazonSmileSteps {
    .amazonSmileStep {
      font-size: 13px;
      font-weight: 500;
      margin-bottom: 5px;

      svg {
        margin: 0 5px;
      }
    }
  }
}

@media (max-width: 1023px) {
  .amazonSmile {
    background-image: none !important;
  }

  .amazonSmileLeft {
    width: 100% !important;
  }

  .amazonSmileLogo {
    display: flex !important;
  }
}