.footer {
  background: #F1F1F1;

  .footerTop {
    padding: 100px 0;

    .footerTopContainer {
      .footerTopFirst {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 90px;

        .footerTopSocial {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          a {
            transition: color 0.2s ease-in-out;

            &:hover {
              color: #D11A6D;
            }
          }
  
          svg {
            font-size: 36px;
            padding: 0 15px;
          }
        }

        .weSupport {
          display: none;
        }
      }

      .footerTopLast {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        .footerTopNavSection {
          width: 16.66%;
          padding-right: 30px;

          .footerTopNavSectionTitle {
            font-size: 16px;
            font-weight: 600;
            color: #A1A1A1;
            margin-bottom: 20px;
          }

          .footerTopNavSectionLink {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            font-weight: 600;
            margin-bottom: 10px;
            transition: color 0.2s ease-in-out;

            span {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            svg {
              margin-left: 10px;
            }

            &:hover {
              color: #D11A6D;
            }
          }

          .weSupport {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            font-weight: 600;
            margin-top: 30px;

            .title {
              margin-right: 20px;
            }
          }
        }

        .justGiving {
          width: 100%;
          margin-top: 30px;

          a {
            position: relative;
            display: block;
          }
          
          a > span {
            position: unset !important;
          }

          a img {
            object-fit: contain;
            width: 100% !important;
            position: relative !important;
            height: unset !important;
          }
        }
      }
    }
  }

  .footerBottom {
    background: #D11A6D;

    .footerBottomContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      padding: 0 max(min(6vw, 140px), 35px);

      a {
        transition: color 0.2s ease-in-out;

        &:hover {
          color: #FFFFFF;
        }
      }

      .footerBottomLeft,
      .footerBottomRight {
        font-size: 11px;
        font-weight: 700;
        color: #D2D2D2;
      }
    }
  }
}

@media (max-width: 1365px) {
  .footer {
    .footerTop {
      .footerTopLast {
        justify-content: flex-start !important;

        .footerTopNavSection {
          width: 25% !important;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    .footerTop {
      padding: 60px 0 !important;

      .footerTopFirst {
        margin-bottom: 30px !important;
        padding: 0 10px 0 0 !important;

        div:first-child {
          display: none !important;
        }

        .footerTopSocial {
          svg {
            font-size: 26px !important;
            padding: 0 8px !important;
          }
        }

        .weSupport {
          display: flex !important;
        }
      }

      .footerTopLast {
        flex-direction: column !important;

       .footerTopNavSectionMobile {
          width: 100%;
          padding: 30px 0 0 0;
          overflow: hidden;

          .footerTopNavSectionTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 24px;
            margin-left: 9px;
            
            div {
              font-size: 18px;
              font-weight: 600;
            }

            svg {
              font-size: 18px;
              color: #919191;
              transition: all 0.3s;
            }
          }

          .footerTopNavSectionTitleOpen {
            svg {
              transform: rotate(180deg);
            }
          }

          .footerTopNavSectionLink {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 14px 0;

            div {
              font-size: 16px;
              font-weight: 600;
              color: #919191
            }

            svg {
              font-size: 18px;
              color: #D11A6D;
            }
          }
        }
      }
    }

    .footerBottom {
      .footerBottomContainer {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        
        .footerBottomLeft {
          margin-bottom: 10px !important;
        }

        .footerBottomLeft,
        .footerBottomRight {
          text-align: center !important;
        }
      }
    }
  }
}