.infoSection {
  padding: 100px 0 100px 0;

  .infoSectionContainer {

  }

  .infoSectionContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .infoSectionLeft {

  }

  .infoSectionRight {

  }

  .infoSectionTitle {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .infoSectionDescription {
    font-size: 34px;
    font-weight: 600;
  }

  .infoSectionLinks {
    margin-left: 150px;

    .infoSectionLink {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 300px;
      padding: 9px 0;

      div {
        font-size: 16px;
        font-weight: 600;
      }

      svg {
        font-size: 12px;
      }
    }
  }

  .infoSectionButton {
    margin-left: 300px;
  }
}

@media (max-width: 1365px) {
  .infoSectionLinks {
    margin-left: 100px !important;
  }

  .infoSectionButton {
    margin-left: 200px !important;
  }
}

@media (max-width: 1023px) {
  .infoSection {
    padding: 100px 0 100px 0 !important;
  }

  .infoSectionContent {
    flex-direction: column !important;
  }

  .infoSectionLeft {
    width: 100%;
  }

  .infoSectionRight {
    width: 100%;
  }

  .infoSectionLinks {
    margin: 20px 0 0 0 !important;

    .infoSectionLink {
      width: 100% !important;
    }
  }

  .infoSectionButton {
    margin: 20px 0 0 0 !important;

    > * {
      margin: 0 auto;
    }
  }
}